import React, { useState } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import ReactMarkdown from "react-markdown/with-html";
import { LinkRenderer } from "../../utils/utilities";

//components
import useSetPageData from "../../utils/useSetPageData";
import Hero from "../../components/panels/Hero";
import SwitchComponent from "../../components/panels/Switch";
import ComponentHeader from "../../components/panels/ComponentHeader";
import SEO from "../../components/seo";

//styles
import s from "./our-team.module.scss";

function TeamMember({ id, name, position, LinkedinURL, Bio, ProfilePic }) {
  const {
    childImageSharp: { fixed }
  } = ProfilePic;

  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);

  return (
    <li className={s.bioItem}>
      <div onClick={toggleOpen} className={s.itemContainer} role="button">
        <div className={s.main}>
          <div className={s.picAndName}>
            <figure className={s.portraitSection}>
              <Img
                fixed={fixed}
                className={s.portrait}
                alt={`portrait of leadership team member ${name}`}
              />
            </figure>
            <div className={s.nameSection}>
              <h2 className={s.bioName}>{name}</h2>
              <h3 className={s.bioPosition}>{position}</h3>
            </div>
          </div>
          <div className={s.bioContainer}>
            <i
              className={`fas fa-chevron-circle-right ${s.lIcon} ${
                open ? s.iconOpen : null
              }`}
            ></i>
            <span className={s.viewMore}>View More</span>
          </div>
        </div>
        <article className={s.bioText} style={open ? {} : { maxHeight: "0" }}>
          <p className={s.bioP}>{Bio}</p>
          <a
            className={s.linkedinLink}
            href={LinkedinURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              className="fab fa-linkedin"
              style={{ color: "rgb(14, 118, 168)" }}
            ></i>
          </a>
        </article>
      </div>
    </li>
  );
}

export default function OurTeamPage({ location, data }) {
  const {
    SEO: seo,
    hero,
    teams_overview_image,
    overview_description,
    team_members
  } = data.strapiPaTeam;

  // return <h1>fixing</h1>
  // const leadershipTeam = data.allStrapiLeadershipTeamMember.edges.map(
  //   ({ node }) => node
  // );

  useSetPageData(location);

  return (
    <>
      <SEO
        {...{
          title: `${hero[0].main_heading} | About`,
          description: seo.description
        }}
      />
      <Hero
        heading={hero[0].main_heading}
        icon={hero[0].about_icon.childImageSharp.fixed}
      />
      <ComponentHeader
        bgColor={"white"}
        textColor={"purple"}
        text={"Overview"}
      />
      <SwitchComponent
        contents={[
          {
            image: <Img fluid={teams_overview_image.childImageSharp.fluid} />,
            content: (
              <ReactMarkdown
                source={overview_description}
                escapeHtml={false}
                renderers={{ link: LinkRenderer }}
              />
            )
          }
        ]}
      />

      <div
        className={`container-fluid panel-padding`}
        style={{ paddingBottom: 0 }}
      >
        <div className={`container`}>
          <div className={`row`}>
            <div className={`col-sm-12`}>
              <h3 className={`${s.ourTeamTitle} ${s.bioHeader}`}>Leadership</h3>
            </div>
          </div>
        </div>
      </div>

      <div className={`container-fluid panel-padding pt-0`}>
        <div className={`container`}>
          <div className={`row`}>
            <div className={`col-sm-12`}>
              <section style={{ height: "max-content" }} id={s.leadership}>
                <ul className={s.bioColumn}>
                  {team_members.map(e => (
                    <TeamMember key={e.id} {...e} />
                  ))}
                </ul>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export const pageQuery = graphql`
  query {
    strapiPaTeam {
      SEO {
        description
      }
      hero {
        main_heading
        main_subheading
        about_icon {
          childImageSharp {
            fixed(width: 100, height: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      teams_overview_image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      overview_description
      team_members {
        Bio
        LinkedinURL
        name
        position
        id
        ProfilePic {
          childImageSharp {
            fixed(height: 180, width: 180) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;
